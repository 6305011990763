export default defineNuxtRouteMiddleware(async (to, from) => {
  const toPath = to.fullPath.replace(/^\/+/, '').split('#')[0]
  const fromPath = from.fullPath.replace(/^\/+/, '').split('#')[0]

  if (import.meta.client && toPath === fromPath) {
    return
  }

  // TODO FRAN DEPURAR EL ENDPOINT PORQUE poniendo solo perfumeria sin /es peta.
  const { data, error } = await useAsyncData('route', () =>
    $fetch(`/api/public/router/${toPath}`)
  )
})
